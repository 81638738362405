/* Ensure the path in your project structure matches */
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');

/* General Styles */
:root {
  --background-color: #282c34; /* Darker shade for depth */
  --button-color: #61dafb; /* Soft blue for a techy feel */
  --text-color: #ffffff; /* White for readability */
  --button-hover-color: #9ad0f5; /* Lighter blue for interaction */
}

body, html {
  height: 100%;
  margin: 0;
}

body {
  font-family: 'Orbitron', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  text-align: center;
}

header, footer {
  padding: 20px 0;
}

header {
  background-color: #333;
  color: #61dafb;
}

footer {
  background-color: #1a1a1a;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 40px); /* Adjust height based on header/footer */
}

.dashboard {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

button {
  background-color: var(--button-color);
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: var(--button-hover-color);
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Rest of the styles remain unchanged */

/* Responsive Design */
@media (max-width: 768px) {
  .dashboard {
    width: 95%;
  }

  button {
    padding: 10px;
    font-size: 14px;
  }
}
/* At the bottom of your main.css file */
.version {
  color: #4dff88; /* Or any color that fits your theme */
  margin-top: 10px;
}